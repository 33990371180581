import React, {useState} from 'react';
import {Button, FormControl, FormHelperText, FormLabel, Input} from "@chakra-ui/react";
import {noitficationService} from "../../plugins/axios";

const PushView = () => {


    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    function sendNotification() {
        noitficationService.put('notification?pkg=com.se.raiseapp', {
            notification:{
                title: title,
                text: text,
                images:[]

            }
        })
    }



    return (
        <div>
            <div style={{width:"30vw", marginLeft:"8px"}}>
            <FormControl>
                <FormLabel>Заголовок</FormLabel>
                <Input onChange={(e)=>{setTitle(e.target.value)}} type='text' />
            </FormControl>
            <FormControl style={{marginTop: "8px"}}>
                <FormLabel>Тело</FormLabel>
                <Input onChange={(e)=>{setText(e.target.value)}} type='text' />
            </FormControl>
            <Button onClick={sendNotification} style={{marginTop: "8px"}} colorScheme={'green'}>Отправить</Button>
            </div>
        </div>
    );
};

export default PushView;