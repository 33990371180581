import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {api} from "../../plugins/axios";

const OauthView = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [state, setState] = useState({})
    const navigate = useNavigate()

    useEffect(()=>{
        let hash = document.location.hash
        let test = hash.split('')
        test[0] = '?'
        test = test.join('')
        var token = /access_token=([^&]+)/.exec(document.location.hash)[1];
        const urlParams = new URLSearchParams(test)
        let state = JSON.parse(urlParams.get('state'))

        if (token && state){
            api.put(`app/${state.app_id}/appmetrika`, {oauth_token: token, _id: state._id})
                .then((res)=>{
                    navigate(`../app/${state.app_id}/stats`)
                    console.log(res.data)
                })
        } else {
            navigate('../apps')
        }
        setState({state, access_token: token})
    }, [])

    return (
        <div>
      3123123
        </div>
    );
};

export default OauthView;