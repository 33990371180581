import React, {useEffect, useState} from 'react';
import {api} from "../../plugins/axios";
import styles from "./CheckList.module.css";
import "./CheckList.css"
import {
    Button,
    FormControl, FormLabel, IconButton, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, useToast
} from "@chakra-ui/react";
import user from "../../store/user";
import TasksList from "../../compopnents/TasksList/TasksList";
import {DownloadIcon} from "@chakra-ui/icons";


const CheckList = () => {

    const toast = useToast()
    const [inputValue, setInputValue] = useState('')
    const [chosenList, setChosenList] = useState({})
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
    const [state, setState] = useState([]);

    useEffect(()=>{
        api('todo')
            .then((res)=>{
                setState(res.data)
            })
    }, [])

    function onCreateModalOpen() {
        setCreateModalIsOpen(true)
    }
    function onCreateModalClose() {
        setCreateModalIsOpen(false)
    }

    function addNewItemToList(item) {
        setState([item,...state ])
    }

    function createNewList() {
        api.post("/todo", {title: inputValue, user_from:user.userData._id })
            .then((res)=>{
                addNewItemToList(res.data)
                onCreateModalClose()
                clearInput()
                toast({
                    title: 'Создание списка',
                    description: "Список успешно создан!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })

            })
    }
    function clearInput() {
        setInputValue('')
    }
    function clearChosenList() {
        setChosenList({})
    }
    function onExport() {
        api({url:'todo/tasks/export', responseType: 'blob'})
            .then( response => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'tasks.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    }
    // function changeItemStatus(item) {
    //     setState(state.map((mapItem) => {
    //         if (item._id === mapItem._id) {
    //             return {...item, completed: !item.completed}
    //         } else {
    //             return mapItem
    //         }
    //     }))
    //     api.put(`checklist/${item._id}`, {...item, completed: !item.completed, user_from: user.userData._id})
    // }

    return (
        <div className={styles.App}>
                <div className={styles.todoBlock}>
                    <div className={styles.btnBlock} >
                        <Button className={styles.addListBtn} onClick={onCreateModalOpen} colorScheme={'green'}>Добавить список</Button>
                        <IconButton className={styles.exportBtn} icon={<DownloadIcon />} onClick={onExport} colorScheme={'gray'}>Экспорт</IconButton>
                    </div>

                    <div className={styles.todoList}></div>

                    {state.map((item)=> (
                        <div style={chosenList._id === item._id ? {color:"white", backgroundColor:"#3182ce", borderRadius:"4px"} : {}} onClick={()=>{setChosenList(item)}} className={styles.todoItem} key={item._id}>
                            {item?.title}
                        </div>
                    ))}
                </div>
            <TasksList clearChosenList={clearChosenList} setTodos={setState} todos={state} todo={chosenList}></TasksList>



            <Modal
                isOpen={createModalIsOpen}
                onClose={onCreateModalClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Добавление нового списка</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Название</FormLabel>
                            <Input value={inputValue} onChange={(e)=>{setInputValue(e.target.value)}}  placeholder='Название' />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={createNewList} colorScheme='green' mr={3}>
                            Добавить
                        </Button>
                        <Button onClick={onCreateModalClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            </div>
    );
};

export default CheckList;
