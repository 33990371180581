import React, {useState} from 'react';
import styles from './MetrikaInfo.module.css'
import {Button, FormControl, FormLabel, Input, useToast} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {api} from "../../../plugins/axios";

const MetrikaInfo = () => {
    const [state, setState] = useState({});
    let { id } = useParams();
    const toast = useToast()

    useEffect(()=>{
        api(`app/${id}/appmetrika`)
            .then((res)=>{
                setState(res.data)
            })
    }, [])

    async function updateChanges() {
        api.put(`app/${id}/appmetrika`, state)
            .then((res)=>{
                if(res.status === 200) {
                    toast({
                        title: 'Данные успешно изменены.',
                        description: "Информация об AppMetrika обновлена.",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
    }
    return (
        <div className={styles.infoWrapper}>
            <div className={styles.mainForm}>
                <FormControl className={styles.formControl}>
                    <FormLabel>Login</FormLabel>
                    <Input onChange={(e)=>{setState({...state, login:e.target.value})}} value={state.login} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Password</FormLabel>
                    <Input onChange={(e)=>{setState({...state, password:e.target.value})}} value={state.password} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Client ID</FormLabel>
                    <Input onChange={(e)=>{setState({...state, client_id:e.target.value})}} value={state.client_id} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Client Secret</FormLabel>
                    <Input onChange={(e)=>{setState({...state, client_secret:e.target.value})}} value={state.client_secret} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>App ID</FormLabel>
                    <Input onChange={(e)=>{setState({...state, appmetrika_id:e.target.value})}} value={state.appmetrika_id} type='text' />
                </FormControl>

            </div>
            <div className={styles.cardFooter}>
                <div className={styles.cardFooterActions}>
                    <Button >Назад</Button>
                    <Button onClick={updateChanges} style={{marginLeft:'8px'}} colorScheme={'blue'}>Сохранить</Button></div>

            </div>
        </div>
    );
};

export default MetrikaInfo;
