import axios from "axios";

export const api = axios.create(
    {
        baseURL: "https://app-panel.ru/api/",
        // baseURL: "http://localhost:5050/api/",
        headers : {
            'Authorization': localStorage.token ? "Bearer " + localStorage.token : null,
        }
    });
export const noitficationService = axios.create(
    {
        // baseURL: "https://app-panel.ru/api/",
        baseURL: "http://3.73.74.20/api/",
        headers : {
            'Authorization': localStorage.token ? "Bearer " + localStorage.token : null,

        }
    });
api.interceptors.response.use((response)=>{return response}, (error) => {
    if (error.response && error.response.status === 403) {
        window.location.href='/auth'
    } else return error
});




