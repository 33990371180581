import React, {useEffect, useState} from 'react';
import styles from './Login.module.css'
import {Box, Button, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import {api} from "../plugins/axios";
import {useNavigate} from "react-router-dom";
import user from "../store/user";
;

const Login = () => {
    let navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState('');


    useEffect(() => {
    if (localStorage.getItem('token')){
        navigate('/apps',{replace: true})
    }
    }, []);


    function loginRequest() {
        setErrorText('')
        api.post('/login',{
            username: login,
            password: password
        })
            .then(async (response) => {
                if (response.response?.status === 400) {
                    await setErrorText(response.response.data.message)
                } else {
                    // await saveUserInfo(response.data.token, response.data.user)
                    // await navigate('/apps', {replace: true})
                    user.authUser(response.data.token,JSON.stringify(response.data.user))

                }

            })
    }
    function showPassHandler(){
        setShowPassword(!showPassword)
    }
    function loginHandler(event) {
        setLogin(event)
    }
    function passwordHandler(event) {
        setPassword(event)
    }
    return (
        <div className={styles.App}>
            <Box className={styles.mainBox} maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                <span className={styles.appTitle}><b>App Panel</b></span>
                <Input onChange={(e)=>{loginHandler(e.target.value)}} placeholder='Логин' />
                <InputGroup size='md'>
                    <Input
                        onKeyPress={(e)=>( e.key === 'Enter' ? loginRequest(e)  : console.log(e.key)  )}
                        pr='4.5rem'
                        onChange={(e)=>{passwordHandler(e.target.value)}}
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Пароль'
                    />
                    <InputRightElement width='6rem'>
                        <Button style={{marginRight:'1rem'}} h='1.75rem' size='sm' onClick={showPassHandler}>
                            {showPassword ? 'Скрыть' : 'Показать'}
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <span className={styles.errorText}>{errorText}</span>
                <Button onClick={loginRequest} disabled={!login || !password ? true : false } colorScheme='blue'>Войти</Button>
                <div style={{margin: '0 auto'}} id={'signInDiv'}></div>
            </Box>
        </div>
    );
};

export default Login;
