import React, {useEffect, useState} from 'react';
import styles from './Apps.module.css'
import {
    Accordion, AccordionButton, AccordionItem,
    AccordionPanel,
    Box,
    Button,
    IconButton, Input,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, useToast
} from "@chakra-ui/react";
import {api} from "../../plugins/axios";
import {
    DeleteIcon, EditIcon
} from '@chakra-ui/icons'
import {Link} from 'react-router-dom'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css'
import SearchInput from 'react-search-input'
import {useNavigate} from "react-router-dom";
const Apps = () => {
    const toast = useToast()
    const navigate = useNavigate()

    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [apps, setApps] = useState([]);
    const [newAppName, setNewAppName] = useState('');

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [deleteAppName, setDeleteAppName] = useState('');
    const [selectedApp, setSelectedApp] = useState({});
    const [searchItems, setSearchItems] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [searchIsFocused, setSearchIsFocused] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [ipp, setIpp] = useState(30);

    function onCreateOpen() {
        setIsCreateOpen(true)
}
    function onCreateClose() {
        setNewAppName('')
        setIsCreateOpen(false)
}
    function onDeleteOpen(app) {
        setIsDeleteOpen(true)
        setSelectedApp(app)
    }
    function onDeleteClose() {
        setDeleteAppName('')
        setIsDeleteOpen(false)
    }
function createNewApp() {
        api.post('app',{name: newAppName})
            .then((res)=>{
                setNewAppName('')
                setApps({...apps, docs:[res.data, ...apps.docs]})
                onCreateClose()
                toast({
                    title: 'Приложение создано.',
                    description: "Приложение было создано.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            })
}
    function goToApp(link) {
        navigate(link)
    }

    function hideSearchPanel(e){
        console.log(e)
        setSearchIsFocused(false)
    }

    function deleteApp() {
        if (deleteAppName === selectedApp.name){
            api.delete(`app/${selectedApp._id}`)
                .then(()=>{
                    setDeleteAppName('')
                    setApps({...apps, docs:apps.docs.filter(item => item._id !== selectedApp._id)})
                    toast({
                        title: 'Приложение удалено.',
                        description: "Приложение было удалено.",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                    onDeleteClose()
                })
        }

    }

    function handlePageClick(event) {
        console.log(event)
        api(`app/?ipp=${ipp}&page=${event}`)
                    .then((res) => {
                        setApps(res.data)
                        setCurrentPage(event)
                    })

    }

    useEffect(() => {
        api(`app/?ipp=${ipp}&page=${currentPage}`)
            .then((res)=>{
                setApps(res.data)
            })
    }, [currentPage]);

    function defineStatusColor(id){
        if (id === '62ebf008fc09c6e3781c88e3'){
            return '#0064dc'
        } else if (id === '62ebee46fc09c6e3781c88e2'){
            return '#007300FF'
        } else if (id === '62f0310a218d42d42614b71a'){
            return 'red'
        } else if (id === '62ebf018fc09c6e3781c88e4'){
            return 'red'
        }
    }

    async function searchByName(text){
        setSearchInputValue(text)
        if (!text) return
       api(`app?q=${text}`)
           .then(({data}) => {
               setSearchItems(data)
        })
    }



    return (
        <div className={styles.App}>
            <Modal onClose={onDeleteClose} isOpen={isDeleteOpen} isCentered>
                <ModalOverlay></ModalOverlay>
                <ModalContent>

                    <ModalHeader>Удаление приложения</ModalHeader>

                    <ModalCloseButton />
                    <ModalBody>
                        Вы уверены что хотите удалить приложение? Удаление приведет к очистке всех данных. <br/> Введите ниже - <b>{selectedApp.name}</b> для удаления приложения.
                        <Input style={{marginTop:'8px'}} onChange={(e)=>{setDeleteAppName(e.target.value) }} placeholder={'-'}></Input>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onDeleteClose}>Отмена</Button>
                        <Button disabled={selectedApp.name !== deleteAppName}  style={{marginLeft:"8px"}} colorScheme={'red'} onClick={deleteApp}>Удалить</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <header>
                <h1 className=  {styles.AppTitle}>Приложения</h1>
                <div className={styles.searchMainBlock}>
                    <SearchInput  onFocus={()=>{setSearchIsFocused(true)}}   placeholder={'Поиск'} throttle={200} className={styles.searchInput} onChange={searchByName} />
                    <div className={styles.searchBlock}>

                        {searchItems.length != 0 && searchIsFocused && searchInputValue && searchItems.map(app => (
                            <div onClick={(e)=>{ goToApp(app._id) }}  className={styles.searchAppItem} key={app.id}>
                                <div className={styles.appNameSearchBlock}>{app.name}</div>

                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.actionBlock}>
                    <Button  onClick={onCreateOpen} colorScheme='green'>Добавить приложение</Button>

                    <Modal onClose={onCreateClose} isOpen={isCreateOpen} isCentered>
                        <ModalOverlay></ModalOverlay>
                        <ModalContent>

                            <ModalHeader>Новое приложение</ModalHeader>

                            <ModalCloseButton />
                            <ModalBody>
                                <Input onChange={(e)=>{setNewAppName(e.target.value) }} placeholder={'Название приложения'}></Input>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={onCreateClose}>Отмена</Button>
                                <Button disabled={!newAppName} style={{marginLeft:"8px"}} colorScheme={'green'} onClick={createNewApp}>Добавить</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </div>
            </header>
            {
                apps.docs &&

                <Accordion allowMultiple className={styles.accWrapper}>
                    {
                        apps.docs.map((app) => (
                                <AccordionItem className={styles.accItem}>
                                    <AccordionButton className={styles.actionBlockAcc}>
                                    <Box key={app._id} className={styles.appItem} maxW='100%' borderWidth='1px' borderRadius='lg'
                                         overflow='hidden'>
                                        <div className={styles.appItemMainBlock}>
                                            <img className={styles.appImg} src={app.icon ?  api.defaults.baseURL +  app.icon.path : api.defaults.baseURL + 'images/empty.png'} alt=""/>
                                            <Link to={`${app._id}`} className={styles.appName}>{app.name} </Link>
                                            {app.package_name &&
                                                <span className={styles.appPackage}>( {app.package_name} )</span>
                                            }
                                        </div>

                                        <div className={styles.appItemPropBlock}>

                                            <div className={styles.appItemProp}>
                                                Статус:
                                                <div style={{color: defineStatusColor(app.status._id), fontWeight:'500'}}>
                                                    {app.status.value}
                                                </div>
                                            </div>
                                            <div className={styles.appItemProp}>
                                                Статус Admob:
                                                <div style={{color: defineStatusColor(app.status._id), fontWeight:'500'}}>
                                                    {app.admob_status.value}
                                                </div>
                                            </div>
                                        </div>


                                        <div className={styles.appActionBlock}>
                                            <Link to={`${app._id}`}>
                                            <IconButton onClick={(e) => {

                                            }} title='Редактировать приложение'  icon={<EditIcon/>}/>
                                        </Link>
                                            <IconButton onClick={(e) =>
                                            {
                                                e.preventDefault()
                                                onDeleteOpen(app)
                                            }} title='Удалить приложение' icon={<DeleteIcon/>}/>
                                        </div>

                                    </Box>


                                    </AccordionButton>
                                    <AccordionPanel className={styles.accPanel} pb={4}>

                                        <div className={styles.accPanelFirstBlock}>
                                            <div><b>VPS</b> <span  style={{color: defineStatusColor(app.status._id), fontWeight:'500'}}>{app.vps_ip}</span></div>
                                            {app.name === "TEST APP" &&
                                                <div><Button onClick={()=>{navigate(`../push/${app._id}?pkg=com.se.raiseapp`)}} colorScheme={'green'}>Пуши (<b
                                                    style={{color: "red"}}>!!!TEST!!!</b>)</Button></div>
                                            }
                                            <div><Button onClick={()=>{navigate(`../apps/${app._id}/stats?pkg=${app.package_name}`)}} colorScheme={'blue'}>Статистика</Button></div>
                                            {/*<div className={styles.backlogInfo}><b>Бэклог:</b><span> {app.last_message?.text}</span> </div>*/}
                                        </div>
                                    </AccordionPanel>
                                </AccordionItem>
                        ))
                    }
                </Accordion>
            //     <div className={styles.appsWrapper}>
            // {
            //     apps.docs.map((app) => (
            //
            //         <Accordion allowMultiple >
            //             <AccordionItem>
            //                 <h2>
            //                     <AccordionButton>
            //                         <Box flex='1' textAlign='left'>
            //                             Section 1 title
            //                         </Box>
            //                         <AccordionIcon />
            //                     </AccordionButton>
            //                 </h2>
            //                 <AccordionPanel pb={4}>
            //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            //                     tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            //                     veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
            //                     commodo consequat.
            //                 </AccordionPanel>
            //             </AccordionItem>
            //         </Accordion>
            //     ))
            // }
            //     </div>
            }
            <div className={styles.paginationBlock}>
           <Pagination

               current={currentPage}
               onChange={handlePageClick}
               total={apps.totalDocs}
               pageSize={ipp}
           ></Pagination>
            </div>
        </div>
    );
};

export default Apps;
