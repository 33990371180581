import React, {useState} from 'react';
import styles from './AdmobInfo.module.css'
import {Button, FormControl, FormLabel, Input, useToast} from "@chakra-ui/react";
import {api} from "../../../plugins/axios";
import {useParams} from "react-router-dom";
import {useEffect} from "react";

const AdmobInfo = () => {
    const [state, setState] = useState({});
    let { id } = useParams();
    const toast = useToast()

    useEffect(()=>{
        api(`app/${id}/admob`)
            .then((res)=>{
                setState(res.data)
            })
    }, [])
    async function updateChanges() {
        api.put(`app/${id}/admob`, state)
            .then((res)=>{
                if(res.status === 200) {
                    toast({
                        title: 'Данные успешно изменены.',
                        description: "Информация об AdMob обновлена.",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
    }

    return (
        <div className={styles.infoWrapper}>
            <div className={styles.mainForm}>
            <FormControl className={styles.formControl}>
                <FormLabel>Project ID</FormLabel>
                <Input onChange={(e)=>{setState({...state, project_id:e.target.value})}} value={state.project_id} type='text' />
            </FormControl>
            <FormControl className={styles.formControl}>
                <FormLabel>App Open</FormLabel>
                <Input onChange={(e)=>{setState({...state, appopen:e.target.value})}} value={state.appopen} type='text' />
            </FormControl>
            <FormControl className={styles.formControl}>
                <FormLabel>Interstitial</FormLabel>
                <Input onChange={(e)=>{setState({...state, interstitial:e.target.value})}} value={state.interstitial} type='text' />
            </FormControl>
            <FormControl className={styles.formControl}>
                <FormLabel>Native</FormLabel>
                <Input onChange={(e)=>{setState({...state, native:e.target.value})}} value={state.native} type='text' />
            </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel> Ad unit level capping inter</FormLabel>
                    <Input onChange={(e)=>{setState({...state, ad_unit_level_capping_inter:e.target.value})}} value={state.ad_unit_level_capping_inter} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Ad unit level capping open</FormLabel>
                    <Input onChange={(e)=>{setState({...state, ad_unit_level_capping_native:e.target.value})}} value={state.ad_unit_level_capping_native} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>App unit level capping inter</FormLabel>
                    <Input onChange={(e)=>{setState({...state, app_unit_level_capping_inter:e.target.value})}} value={state.app_unit_level_capping_inter} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Ad unit app-level open</FormLabel>
                    <Input onChange={(e)=>{setState({...state, app_unit_level_capping_native:e.target.value})}} value={state.app_unit_level_capping_native} type='text' />
                </FormControl>
            </div>
            <div className={styles.cardFooter}>
                <div className={styles.cardFooterActions}>
                    <Button >Назад</Button>
                    <Button onClick={updateChanges} style={{marginLeft:'8px'}} colorScheme={'blue'}>Сохранить</Button></div>

            </div>
        </div>
    );
};

export default AdmobInfo;
