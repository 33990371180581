/* eslint-disable */
import './App.css';
import {Routes, Route, Link, useNavigate} from "react-router-dom";
import Login from "./views/Login";
import MainPage from "./views/MainPage/MainPage";
import AppLayout from './compopnents/laytouts/AppLayout'
import jwt_decode from "jwt-decode"
import Apps from "./views/Apps/Apps";
import CheckList from "./views/Checklist/CheckList";
import AppInfo from "./views/AppInfo/AppInfo";
import {lazy, Suspense, useEffect} from "react";
import {api} from "./plugins/axios";
import user from "./store/user";
import PushView from "./views/PushView/PushView";
import AppStatsView from "./views/AppStats/AppStatsView";
import OauthView from "./views/AppStats/OauthView";
import StatsView from "./views/StatsView/StatsView";


const Kanban = lazy(() => import('./views/Kanban/Kanban'))
function App() {

    if (!localStorage.token && location.pathname !== '/auth'){
        location.href ='/auth'
    }

    const navigate = useNavigate()

    function handleCallbackResponse(response){
        api.post('/auth/google',{token:response.credential})
            .then(async ({data})=>{
                user.authUser(data.token,JSON.stringify(data.user))
        })
    }

    useEffect(() => {
        /* eslint-disable no-eval */
        google.accounts.id.initialize({
            client_id: "339802483959-71dkr98pvcaquaprls65qbfr0juh8rfs.apps.googleusercontent.com",
            callback:handleCallbackResponse
        })
        /* eslint-disable no-eval */
        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            {theme:"outline",size:"large"}
        )

    }, []);


    useEffect(()=>{
        if (!localStorage.token){
            navigate('/auth')
        }
    }, [])

  return (
    <div id='app' className="App">
        <Routes basename="/index.html">
            <Route path="/auth" element={<Login />} />
            <Route path="/" exact element={<MainPage />}>
                <Route exact path="/apps" element={<Apps />} />
                <Route exact path="/kanban" element={<Suspense ><Kanban /></Suspense>} />
                <Route exact path="/checklist" element={<CheckList />} />
                <Route exact path="/apps/:id" element={<AppInfo />} />
                <Route exact path="/push/:id" element={<PushView />} />
                <Route exact path="/apps/:id/stats/" element={<AppStatsView />} />
                <Route exact path="/stats/" element={<StatsView />} />
                <Route exact path="/appmetrika/oauth" element={<OauthView />} />
            </Route>


        </Routes>
    </div>
  );
}

export default App;
