import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Select, Table, TableCaption, TableContainer, Tbody,
    Td,
    Tfoot,
    Th, Thead,
    Tr
} from "@chakra-ui/react";
import styles from './StatsView.module.css'
import axios from "axios";
import {api} from "../../plugins/axios";
import DatePicker from "react-date-picker";
const StatsView = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [appsOpts, setAppsOpts] = useState([])
    const [splitByDay, setSplitByDay] = useState(false)
    const [metrikaCounters, setMetrikaCounters] = useState([])
    const [yaCreds, setYaCreds] = useState({})
    const [chosenLandings, setChosenLandings] = useState([])
    const [landings, setLandings] = useState([])
    const [kkID, setKKID] = useState(null)
    const [appID, setAppID] = useState('')
    const [app, setApp] = useState({})
    const [campaignID, setCampaignID] = useState('')
    const [statsType, setStatsType] = useState('installs')
    const [statsData, setStatsData] = useState(null)

    const [appMetrikaStats,setAppMetrikaStats] = useState('')

    useEffect(()=>{
        getOptApps()
        fetchMetrikaCounters()

    },[])

    async function fetchMetrikaCounters(){
        const {data} = await axios("https://api-metrika.yandex.net/management/v1/counters", {headers:{Authorization: 'OAuth y0_AgAAAABpAxMKAAk3swAAAADdjrnuiQ9ML3LNQRuvx2rYw8jqGJcARgI'}})
        console.log(data)
        await setLandings(data.counters)
    }
    async  function fetchAppmetrikaData(){
        console.log(chosenLandings)
        const {data} = await api.post(`/stats/installs?id=${appID}`,
            {
                date1: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`,
                date2: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`,
                kkID: kkID,
                split: splitByDay,
                metrika_id: chosenLandings,
            })
        await setStatsData(data)
    }

    function submitForm(e) {
        e.preventDefault()
        console.log(e.target.value)
        getStats()
    }
    async function getStats(options) {
        fetchAppmetrikaData()

    }
    async function getOptApps(id){
        try {
            const {data} = await api.get(`stats/opts/apps`)
            setAppsOpts(data)
        } catch (e) {
            console.error(e)
        }
    }
    async function getAppById(id){
        const res = appsOpts.filter((item)=>{
            if (item._id === id){
                return item
            }
        })
        console.log(res[0])
        return res[0]
    }
    async function onAppSelectChangeHandler(e){
        const id = e.target.value
        const app = await getAppById(id)
        console.log(app)

        await setApp(app)
        await setAppID(id)
        await getYaCreds(id)

    }
    async function getYaCreds(id){
        try {
            await setIsLoading(true)
            console.log(id)
            const {data} = await api(`app/${id}/yacreds`)
            setYaCreds(data)
            await setIsLoading(false)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div>
            <form onSubmit={(e)=>{submitForm(e)}} className={styles.optBlock}>
                <FormControl className={styles.formItem}>
                    <FormLabel className={styles.formTitle}>Лендинг<span className={styles.reqStar}>*</span></FormLabel>
                    <Select  onChange={(e)=>{setChosenLandings(e.target.value)}} placeholder={'...'} isRequired={true} >
                        { landings.map((item)=>(
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </Select>
                </FormControl>
            <FormControl className={styles.formItem}>
                <FormLabel className={styles.formTitle}>utm_medium<span className={styles.reqStar}>*</span></FormLabel>
                <Input onChange={(e)=>{setKKID(e.target.value)}} value={kkID} isRequired={true} />
            </FormControl>
            <FormControl className={styles.formItem}>
                <FormLabel className={styles.formTitle}>Приложение</FormLabel>
                <Select value={appID} onChange={(e)=>{onAppSelectChangeHandler(e)}}  placeholder='...'  isRequired={true}>
                    {appsOpts.map((item)=>(
                        <option key={item._id} value={item._id}>{item.name}</option>
                    ))}

                </Select>
            </FormControl>
            <FormControl className={styles.formItem}>
                <FormLabel className={styles.formTitle}>Campaign ID</FormLabel>
                <Input type='email' />
            </FormControl>
            {/*<FormControl className={styles.formItem}>*/}
            {/*    <FormLabel className={styles.formTitle}>Тип <span className={styles.reqStar}>*</span></FormLabel>*/}
            {/*    <Select value={statsType} placeholder='Тип' >*/}
            {/*        <option value='installs'>Установки</option>*/}

            {/*    </Select>*/}
            {/*</FormControl >*/}
                <div className={styles.datePickerBlock}>
                    <div className={styles.datePickerItem}>
                        <span>From</span>
                    <DatePicker className={styles.datepicker}  style={{height:'40px !important'}} value={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                    <div className={styles.datePickerItem}>
                        <span>To</span>
                    <DatePicker className={styles.datepicker}  style={{height:'40px !important'}} value={endDate} onChange={(date) => setEndDate(date)} />
                    </div>
                </div>
                <div className={styles.checkboxBlock}>
                    <div className={styles.checkboxItem}>
                        <Checkbox value={splitByDay} onChange={(e)=>{setSplitByDay(e.target.checked)}}>По дням</Checkbox>
                    </div>
                    <div className={styles.checkboxItem}>
                        <Checkbox isDisabled>-</Checkbox>
                    </div>
                </div>

                <Button disabled={isLoading ? true : false} colorScheme={'blue'} className={`${styles.formItem} ${styles.submitBtn}`} type='submit'>Поиск</Button>
            </form>
        <div>
            <TableContainer>
                <Table variant='striped' colorScheme='gray'>
                    <Thead>
                        <Tr>
                            <Th>Дата</Th>
                            <Th>Package</Th>
                            <Th>Визиты в GP</Th>
                            <Th>Визиты Ленд</Th>
                            <Th>Установок</Th>
                            <Th>Конверсия</Th>

                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            {statsData && statsData.body.map((item) => (
                                <Td>
                                    {item}
                                </Td>
                                ))}
                        </Tr>

                    </Tbody>

                </Table>
            </TableContainer>
        </div>
        </div>
    );
};

export default StatsView;