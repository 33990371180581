import React, {useEffect, useState} from 'react';
import styles from "../MetrikaInfo/MetrikaInfo.module.css";
import {Button, FormControl, FormLabel, Input, useToast} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {api} from "../../../plugins/axios";

const ConfigInfo = () => {

    const [state, setState] = useState({});
    let {id} = useParams();
    const toast = useToast()

    useEffect(() => {
        api(`app/${id}/config`)
            .then((res) => {
                setState(res.data)
            })
    }, [])

    async function updateChanges() {
        api.put(`app/${id}/config`, state)
            .then((res) => {
                if (res.status === 200) {
                    toast({
                        title: 'Данные успешно изменены.',
                        description: "Информация об AppMetrika обновлена.",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
    }

        return (
            <div>
                    <div className={styles.mainForm}>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Схема</FormLabel>
                            <Input onChange={(e) => {
                                setState({...state, scheme: e.target.value})
                            }} value={state.scheme} type='text'/>
                        </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Порт</FormLabel>
                            <Input onChange={(e) => {
                                setState({...state, port: e.target.value})
                            }} value={state.port} type='number'/>
                        </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>ID Канала</FormLabel>
                            <Input onChange={(e) => {
                                setState({...state, chanel_id: e.target.value})
                            }} value={state.chanel_id} type='text'/>
                        </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Имя Канала</FormLabel>
                            <Input onChange={(e) => {
                                setState({...state, chanel_name: e.target.value})
                            }} value={state.chanel_name} type='text'/>
                        </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Иконка Уведомления</FormLabel>
                            <Input onChange={(e) => {
                                setState({...state, notification_icon: e.target.value})
                            }} value={state.notification_icon} type='text'/>
                        </FormControl>

                    </div>
                    <div className={styles.cardFooter}>
                        <div className={styles.cardFooterActions}>
                            <Button>Назад</Button>
                            <Button onClick={updateChanges} style={{marginLeft: '8px'}}
                                    colorScheme={'blue'}>Сохранить</Button></div>

                    </div>
            </div>
        );
};

export default ConfigInfo;