import React, {useEffect, useState} from 'react';
import styles from './TasksList.module.css'
import {api} from "../../plugins/axios";
import {
    Button, ButtonGroup, Checkbox,
    FormControl, FormLabel, IconButton, Input, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Radio, Spinner, useToast
} from "@chakra-ui/react";
import './TasksList.css'
import user from "../../store/user";
import {DeleteIcon, EditIcon, HamburgerIcon} from "@chakra-ui/icons";
import TaskItem from "./TaskItem/TaskItem";

const TasksList = ({todo, setTodos, todos, clearChosenList}) => {

    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const [deleteItemPret, setDeleteItemPret] = useState({})
    const [inputValue, setInputValue] = useState('')
    const [state, setState] = useState([])
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
    const [deleteTaskModalIsOpen, setDeleteTaskModalIsOpen] = useState(false)


    useEffect(()=>{

        if (Object.keys(todo).length != 0) {
            setIsLoading(true)
            api(`todo/${todo._id}/tasks`)
                .then((res) => {
                    setState(res.data)
                })
                .finally(() => {
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        }
    }, [todo._id])
    function onDeleteTaskModalOpen(item){
        setDeleteItemPret(item)
        setDeleteTaskModalIsOpen(true)
    }
    function onDeleteTaskModalClose(){
        clearDeleteItemPret()
        setDeleteTaskModalIsOpen(false)
    }
    function onDeleteModalOpen(item){
        setDeleteItemPret(item)
        setDeleteModalIsOpen(true)
    }
    function onDeleteModalClose(){
        setDeleteModalIsOpen(false)
    }

    function onCreateModalOpen() {
        setCreateModalIsOpen(true)
    }
    function onCreateModalClose() {
        setCreateModalIsOpen(false)
    }
    function clearInput() {
        setInputValue('')
    }
    function clearTasks() {
        setState([])
    }
    function clearDeleteItemPret() {
        setDeleteItemPret({})
    }
    function addNewItemToList(item) {
        setState([...state, item ])
    }
    function createNewList() {
        api.post(`/todo/${todo._id}/tasks`, {title: inputValue, user_from:user.userData._id })
            .then((res)=>{
                addNewItemToList(res.data)
                onCreateModalClose()
                clearInput()
                toast({
                    title: 'Создание задачи',
                    description: "Задача успешно создана!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })

            })
    }
    function deleteList(item) {
        api.delete(`todo/${item._id}`)
            .then((res)=>{
                console.log('deleted ', res.data)

                setTodos(todos.filter((mapItem)=>{
                    if (mapItem._id === item._id){
                        return
                    } else {
                        return mapItem
                    }
                }))
                clearChosenList()
                onDeleteModalClose()
                clearTasks()
                toast({
                    title: 'Удадение списка',
                    description: "Список успешно удалена!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            })
    }
    function deleteTask(item) {
        api.delete(`todo/${item.todo_id}/tasks/${item._id}`)
            .then((res)=>{
                console.log('deleted ', res.data)

                setState(state.filter((mapItem)=>{
                    if (mapItem._id === item._id){
                        return
                    } else {
                        return mapItem
                    }
                }))
                toast({
                    title: 'Удадение задачи',
                    description: "Задача успешно удалена!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                clearDeleteItemPret()
                onDeleteTaskModalClose()
            })
    }
    function changeItemState(item) {
        api.put(`todo/${item.todo_id}/tasks/${item._id}`, {title: item.title, completed: !item.completed})
            .then(( {data} )=>{
                setState(state.map((mapItem)=>{
                    if (item._id === mapItem._id){
                        return {...item,  completed: !item.completed}
                    }
                    else {
                        return mapItem
                    }
                }))
            })
    }

    return (
        <div className={styles.App}>
            {Object.keys(todo).length !== 0 &&
                <div className={styles.todoTitle}>
                    {todo.title}
                    <div>
                        <Button mr={'1'} onClick={onCreateModalOpen} colorScheme={'green'}>Добавить задачу</Button>
                        <IconButton variant={'unstyled'} disabled mr={'1'} colorScheme={'blue'} icon={<EditIcon />}></IconButton>
                        <IconButton onClick={onDeleteModalOpen} mr={'1'} colorScheme={'red'} icon={<DeleteIcon />}></IconButton>
                    </div>
                </div>
            }
            <div className={styles.taskList}>
                {state.map((item)=>(
                    <TaskItem onDeleteTaskModalOpen={onDeleteTaskModalOpen}  changeItemState={changeItemState} item={item}></TaskItem>
                ))}
            </div>
            {/*delete task*/}
            <Modal
                isOpen={deleteTaskModalIsOpen}
                onClose={onDeleteTaskModalClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Удаление задачи</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        Вы уверены что хотите удалить задачу?
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={()=>{deleteTask(deleteItemPret)}} colorScheme='red' mr={3}>
                            Удалить
                        </Button>
                        <Button onClick={onDeleteTaskModalClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


            {/*delete list modal*/}
            <Modal
                isOpen={deleteModalIsOpen}
                onClose={onDeleteModalClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Удаление списка задач</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        Вы уверены что хотите удалить список? Это приведет к удалению всех задач.
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={()=>{deleteList(todo)}} colorScheme='red' mr={3}>
                            Удалить
                        </Button>
                        <Button onClick={onDeleteModalClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={createModalIsOpen}
                onClose={onCreateModalClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Добавление новой задачи</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Название</FormLabel>
                            <Input value={inputValue} onChange={(e)=>{setInputValue(e.target.value)}}  placeholder='Название' />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={createNewList} colorScheme='green' mr={3}>
                            Добавить
                        </Button>
                        <Button onClick={onCreateModalClose}>Закрыть</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {isLoading &&
                <div className={styles.loadingBlock}><Spinner thickness='4px'
                                                              speed='0.65s'
                                                              emptyColor='gray.100'
                                                              color='green.500'
                                                              size='xl'/></div>
            }
        </div>
    );
};

export default TasksList;