import React, {useEffect, useState} from 'react';
import api from "../../plugins/axios";
import './MainPage.css'
import {Link, Outlet, useNavigate} from "react-router-dom";
import MenuBurger from "react-burger-menu/lib/menus/push";
import styles from './MainPage.module.css'
import {Avatar, IconButton, MenuButton, MenuDivider, MenuItem,Menu, MenuList} from "@chakra-ui/react";
import {HamburgerIcon} from "@chakra-ui/icons";
import user from "../../store/user";
const MainPage = () => {

    const navigate = useNavigate()
    const [sidebarState, setSidebarState] = useState(false);

    function handleOnCloseSidebar(){
        setSidebarState(false)
    }

    function handleOnOpenSidebar(){
        setSidebarState(true)
    }
    function exit() {
        user.unAuthUser()
    }
    useEffect(()=>{
        const pathname = window.location.pathname

        if (pathname === '/'){
            navigate('/apps')
        }
    }, [])

    return (
        <div id={'outer-container'} >
            <MenuBurger onOpen={handleOnOpenSidebar} onClose={handleOnCloseSidebar} isOpen={sidebarState} isClosed={sidebarState} pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
                <Link to={'/apps'} id="apps" className="menu-item">Приложения</Link>
                <Link to={'/kanban'} id="kanban" className="menu-item">Канбан</Link>
                <a href={'https://app-panel.ru/planka'} id="planka" className="menu-item">Канбан (Новый)</a>
                <Link to={'/checklist'} id="checklist" className="menu-item">Чеклист</Link>
                <Link to={'/stats'} id="stats" className="menu-item">Статистика Лендингов</Link>
            </MenuBurger>
            <main id={'page-wrap'}>
                <div className={'side-wrapper'}>
                    <header  className={styles.mainHeader}>
                        <div className={styles.headerPartFirst}>
                        <IconButton
                            colorScheme='white'
                            aria-label='Search database'
                            icon={<HamburgerIcon color={'black'} />}
                            onClick={handleOnOpenSidebar}
                        />
                        <Link to={'apps'} className={styles.appTitle}>AppPanel</Link>
                        </div>
                        <Menu placement={'bottom-end'}>
                            <MenuButton className={styles.avatarButton} ><Avatar size='sm' className={styles.avatarItem} name={user.userData.name} /></MenuButton>
                            <MenuList closeOnSelect className={styles.menuList}>
                                {/*<MenuItem disabled={'true'} icon={<SettingsIcon />} >*/}
                                {/*    Профиль*/}
                                {/*</MenuItem>*/}
                                <MenuDivider />
                                <MenuItem onClick={exit} className={styles.exitBtn}>
                                    Выход
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </header>
                    <div className={'main-wrapper'}>
                        <Outlet></Outlet>
                    </div>
                </div>

            </main>
        </div>
    );
};

export default MainPage;
