import {makeAutoObservable} from "mobx";



class user {
    isAuth = localStorage.token ? true : false
    userData = JSON.parse(localStorage.getItem('userData')) || null
    constructor() {
        makeAutoObservable(this)
        this.userData !== null ?  this.isAuth = true : this.isAuth = false



    }
    async unAuthUser(){
        await localStorage.removeItem('token')
        await localStorage.removeItem('userData')
        window.location.href = '/auth'
    }
    async authUser(token, user){
        this.userData = user
        await localStorage.setItem('token',token)
        await localStorage.setItem('userData',user)
        await localStorage.setItem('userData',user)
        window.location.href = '/apps'
    }
}
export default new user()