import React, {useState} from 'react';
import styles from './KeysInfo.module.css'
import {
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    useToast
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {api} from "../../../plugins/axios";
import {ExternalLinkIcon} from "@chakra-ui/icons";

const KeysInfo = () => {
    const [state, setState] = useState({});
    let { id } = useParams();
    const toast = useToast()

    async function updateChanges() {
        api.put(`app/${id}/keys`, state)
            .then((res)=>{
                if(res.status === 200) {
                    toast({
                        title: 'Данные успешно изменены.',
                        description: "Информация о ключах обновлена.",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
    }
    function openLink(link) {
        window.open(link, "_blank")
    }

    useEffect(()=>{
        api(`app/${id}/keys`)
            .then((res)=>{
                setState(res.data)
            })
    }, [])
    return (
        <div>
            <div className={styles.mainForm}>
                <FormControl className={styles.formControl}>
                    <FormLabel>Ссылка</FormLabel>
                    <InputGroup size='md'>
                        <Input onChange={(e)=>{setState({...state, link:e.target.value})}} value={state.link} type='text' />
                        { state.link &&
                            <InputRightElement>
                                <IconButton onClick={() => {
                                    openLink(state.link)
                                }} icon={<ExternalLinkIcon/>} h='1.75rem' size='sm'></IconButton>
                            </InputRightElement>
                        }
                    </InputGroup>
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Alias</FormLabel>
                    <Input onChange={(e)=>{setState({...state, alias:e.target.value})}} value={state.alias} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Keystore Pass</FormLabel>
                    <Input onChange={(e)=>{setState({...state, keystore_pass:e.target.value})}} value={state.keystore_pass} type='text' />
                </FormControl>
                <FormControl className={styles.formControl}>
                    <FormLabel>Key Pass</FormLabel>
                    <Input onChange={(e)=>{setState({...state, key_pass:e.target.value})}} value={state.key_pass} type='text' />
                </FormControl>
            </div>
            <div className={styles.cardFooter}>
                <div className={styles.cardFooterActions}>
                    <Button >Назад</Button>
                    <Button onClick={updateChanges} style={{marginLeft:'8px'}} colorScheme={'blue'}>Сохранить</Button></div>

            </div>
        </div>
    );
};

export default KeysInfo;
