import React, {useState} from 'react';
import styles from './TaskItem.module.css'
import {Checkbox, IconButton} from "@chakra-ui/react";
import {DeleteIcon, EditIcon} from "@chakra-ui/icons";

const TaskItem = ({item, changeItemState, onDeleteTaskModalOpen}) => {

    const [isItemHover, setIsItemHover] = useState(false)

    return (
                <div onMouseEnter={() => {
                    setIsItemHover(true)
                }} onMouseLeave={() => {
                    setIsItemHover(false)
                }} key={item._id} className={styles.taskItem}>
                    <div onClick={() => {
                        changeItemState(item)
                    }} className={styles.radioBlock}>
                        <Checkbox className={styles.radioBtn} isChecked={item.completed}>{item.title}</Checkbox>
                    </div>
                    {/*{isItemHover &&*/}
                        <div style={isItemHover ? {visibility: "visible"} : {visibility: "hidden"}}  className={styles.taskActionBlock}>
                            <IconButton variant='unstyled' colorScheme='teal' disabled mr={'1'} colorScheme={'blue'} icon={<EditIcon/>}></IconButton>
                            <IconButton variant='solid' colorScheme='teal' onClick={() => {onDeleteTaskModalOpen(item)}} mr={'5'} colorScheme={'red'} icon={<DeleteIcon/>}></IconButton>
                        </div>
                    {/* }*/}
                </div>
    );
};

export default TaskItem;