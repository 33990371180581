import React, {useEffect, useState} from 'react';
import styles from './AppStatsView.module.css'
import {api} from "../../plugins/axios";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Button, Table} from "@chakra-ui/react";
import axios from "axios";

const AppStatsView = () => {

    let navigate = useNavigate()
    let { id } = useParams();
    let params = useParams();
    const [metrikaCreds, setMetrikaCreds] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(()=>{
        console.log(searchParams.get('pkg'))
        api(`app/${id}/appmetrika/creds`)
            .then(({data})=>{
                console.log(data)
                axios(`https://api.appmetrica.yandex.ru/v2/user/acquisition?lang=ru&request_domain=com&id=4134250&date1=2023-02-26&date2=2023-02-26&filters=exists(urlParamKey%3D%3D'utm_medium'%20and%20urlParamValue%3D%3D'467')&accuracy=1&dimensions=publisher&sort=-devices&eventNames=%5B%5D&limit=10&offset=1&source=installation&proposedAccuracy=true&metrics=clicks%2Cdevices%2Cdeeplinks%2Cconversion%2Csessions`, {headers:{Authorization:`OAuth ${data.oauth_token}`}})
                setMetrikaCreds(data)
            })

    },[])
    useEffect(()=>{
        console.log(metrikaCreds)

    },[])

    function parseState() {
        return JSON.stringify({app_id: id, _id: metrikaCreds._id})
    }

    function signInYandex() {
        window.open(`https://oauth.yandex.ru/authorize?response_type=token&client_id=${metrikaCreds.client_id}&redirect_uri=https://app-panel.ru/appmetrika/oauth/&state=${parseState()}`)
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                columns: [
                    {
                        Header: 'First Name',
                        accessor: 'firstName',
                    },
                    {
                        Header: 'Last Name',
                        accessor: 'lastName',
                    },
                ],
            },
            {
                Header: 'Info',
                columns: [
                    {
                        Header: 'Age',
                        accessor: 'age',
                    },
                    {
                        Header: 'Visits',
                        accessor: 'visits',
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                    },
                    {
                        Header: 'Profile Progress',
                        accessor: 'progress',
                    },
                ],
            },
        ],
        []
    )

    return (
        <div>
            {!metrikaCreds?.oauth_token &&
            <Button onClick={signInYandex}>Яндексы</Button>
            }
            <Table columns={columns} ></Table>
        </div>
    );
};

export default AppStatsView;