import React, {useEffect, Suspense, useState, lazy} from 'react';
import styles from './AppInfo.module.css'
import {
    Button,
    FormControl,
    FormLabel, IconButton,
    Input, InputGroup, InputRightElement, Select, Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels, Tabs, useToast
} from "@chakra-ui/react";
import {api} from "../../plugins/axios";
import {useNavigate, useParams} from "react-router-dom";
import AdmobInfo from "../../compopnents/AppInfo/AdmobInfo/AdmobInfo";
import KeysInfo from "../../compopnents/AppInfo/KeysInfo/KeysInfo";
import MetrikaInfo from "../../compopnents/AppInfo/MetrikaInfo/MetrikaInfo";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import ConfigInfo from "../../compopnents/AppInfo/ConfigInfo/ConfigInfo";
const BacklogInfo = lazy(() => import('../../compopnents/AppInfo/BacklogInfo/BacklogInfo'))
const ResourcesInfo = lazy(() => import('../../compopnents/AppInfo/ResorcesInfo/ResourcesInfo'))

const AppInfo = () => {
    const [state, setState] = useState(null);
    const [statusesState, setStatusesState] = useState([]);
    const [admobStatusesState, setAdmobStatusesState] = useState([]);
    const [isResourcesOpenLazy, setIsResourcesOpenLazy] = useState(false)
    const [isBacklogOpenLazy, setIsBacklogOpenLazy] = useState(false)
    let { id } = useParams();
    const toast = useToast()
    const navigate = useNavigate()

    useEffect(()=>{
        api(`app/${id}`)
            .then((res)=>{
                setState(res.data)

            })
    }, [])
    useEffect(()=>{
        api(`statuses/`)
            .then((res)=>{
                setStatusesState(res.data)

            })
    }, [])
    useEffect(()=>{
        api(`admobstatuses/`)
            .then((res)=>{
                setAdmobStatusesState(res.data)

            })
    }, [])

    async function updateChanges() {
        api.put(`app/${id}`, state)
            .then((res)=>{
                if(res.status === 200) {
                    toast({
                        title: 'Данные успешно изменены.',
                        description: "Информация о приложении обновлена.",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
    }
    function openLink(link) {
        window.open(link, "_blank")
    }

    return (
        <div className={styles.App}>
            <header onClick={()=>{navigate(-1)}}>Редактирование приложения</header>
            <div className={styles.mainBlock}>
            <div className={styles.firstBlock}>
                {state != null &&
                    <div className={styles.formBlock}>
                    <FormControl className={styles.formControl}>
                        <FormLabel>Название</FormLabel>
                        <Input onChange={(e) => {
                            setState({...state, name: e.target.value})
                        }} value={state.name} type='text'/>
                    </FormControl>
                    <FormControl className={styles.formControl}>
                    <FormLabel>Наименование пакета</FormLabel>
                    <Input  onChange={(e)=>{setState({...state, package_name:e.target.value})}} value={state.package_name} type='text' />
                    </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Репозиторий</FormLabel>
                            <Input onChange={(e)=>{setState({...state, repo_link:e.target.value})}} value={state.repo_link} type='text' />
                        </FormControl>
                    <FormControl className={styles.formControl}>
                    <FormLabel>Статус GP</FormLabel>
                    <Select onChange={(e)=>{setState({...state, status:e.target.value})}} value={state.status._id}>
                {statusesState.map((item)=>(
                    <option key={item._id} value={item._id}>{item.value}</option>
                    ))}

                    </Select>
                    </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Статус Admob</FormLabel>
                            <Select onChange={(e)=>{setState({...state, admob_status:e.target.value})}} value={state.admob_status}>
                                {admobStatusesState.map((item)=>(
                                    <option key={item._id} value={item._id}>{item.value}</option>
                                ))}

                            </Select>
                        </FormControl>
                    <FormControl className={styles.formControl}>
                    <FormLabel>Возраст</FormLabel>
                    <Input onChange={(e)=>{setState({...state, dw:e.target.value})}} value={state.dw} type='text' />
                    </FormControl>
                    <FormControl className={styles.formControl}>
                    <FormLabel>Политика</FormLabel>

                        <InputGroup size='md'>
                            <Input onChange={(e)=>{setState({...state, policy_link:e.target.value})}} value={state.policy_link} type='text' />
                            { state.policy_link &&
                                <InputRightElement>
                                    <IconButton onClick={() => {
                                        openLink(state.policy_link)
                                    }} icon={<ExternalLinkIcon/>} h='1.75rem' size='sm'></IconButton>
                                </InputRightElement>
                            }
                        </InputGroup>
                    </FormControl>
                    <FormControl className={styles.formControl}>
                    <FormLabel>Версия</FormLabel>
                    <Input onChange={(e)=>{setState({...state, version:e.target.value})}} value={state.version} type='text' />
                    </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Страны</FormLabel>
                            <Input onChange={(e)=>{setState({...state, country:e.target.value})}} value={state.country} type='text' />
                        </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>VPS: </FormLabel>
                            <Input onChange={(e)=>{setState({...state, vps_ip:e.target.value})}} value={state.vps_ip} type='text' />
                        </FormControl>
                        <FormControl className={styles.formControl}>
                            <FormLabel>Тайминг показа рекламы</FormLabel>
                            <Input onChange={(e)=>{setState({...state, timing:e.target.value})}} value={state.timing} type='number' />
                        </FormControl>
                    </div>
                }
                <div className={styles.cardFooter}>
                    <div className={styles.cardFooterActions}>
                        <Button >Назад</Button>
                        <Button onClick={updateChanges} style={{marginLeft:'8px'}} colorScheme={'blue'}>Сохранить</Button></div>

                </div>
            </div>
            <div className={styles.secondBlock}>
                <Tabs className={styles.tabsBlock}>
                <TabList className={styles.tabsList}>
                    <Tab>AdMob</Tab>
                    <Tab>Ключи и пароли</Tab>
                    <Tab>AppMetrika</Tab>
                    <Tab>Config</Tab>
                    <Tab onClick={()=>{setIsResourcesOpenLazy(true)}}>Ресурсы</Tab>
                    <Tab onClick={()=>{setIsBacklogOpenLazy(true)}}>Бэклог</Tab>
                </TabList>

                <TabPanels >
                    <TabPanel>
                        <AdmobInfo></AdmobInfo>
                    </TabPanel>
                    <TabPanel>
                        <KeysInfo></KeysInfo>
                    </TabPanel>
                    <TabPanel>
                        <MetrikaInfo></MetrikaInfo>
                    </TabPanel>
                    <TabPanel>
                        <ConfigInfo></ConfigInfo>
                    </TabPanel>
                    <TabPanel>
                        {isResourcesOpenLazy &&
                            <Suspense fallback={<Spinner />}>
                                <ResourcesInfo></ResourcesInfo>
                            </Suspense>
                        }
                    </TabPanel>
                    <TabPanel style={{padding: 0}}>
                        {isBacklogOpenLazy &&
                            <Suspense сlassName={styles.loader}  fallback={<Spinner />}>
                                <BacklogInfo></BacklogInfo>
                            </Suspense>
                        }
                    </TabPanel>
                </TabPanels>
                </Tabs>
            </div>
            </div>
        </div>
    );
};

export default AppInfo;
